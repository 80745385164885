import { Injectable } from '@angular/core'

@Injectable()
export class CurrentUserService {
  constructor() {}

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('me'))
  }
}
