import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss'],
})
export class StatComponent {
  @Input() icon: string
  @Input() color: 'pink' | 'purple' | 'turquoise' | 'blue'
  @Input() count: number
  @Input() label: string
  @Input() insideStat: { label: string; value: any } | null
  @Input() labelSize: string = '20'
  @Input() valueSize: string = '30'

  constructor() {}

  getColorClass() {
    return 'rocket-' + this.color + '-500'
  }
}
