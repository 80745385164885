import { Component, HostBinding, OnInit } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'
import { ThemeService } from './shared/services/theme.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(public overlayContainer: OverlayContainer, private themeService: ThemeService) {
    if (themeService.getMode()) this.changeTheme('dark-theme')
  }

  @HostBinding('class') componentCssClass

  changeTheme(theme: 'dark-theme' | 'light-theme') {
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) =>
      item.includes('-theme')
    )
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove)
    }
    overlayContainerClasses.add(theme)
    this.componentCssClass = theme
  }

  ngOnInit() {
    this.themeService.modeUpdated.subscribe((dark) =>
      dark ? this.changeTheme('dark-theme') : this.changeTheme('light-theme')
    )
  }
}
