import { EventEmitter, Injectable } from '@angular/core'

@Injectable()
export class ThemeService {
  modeUpdated: EventEmitter<boolean> = new EventEmitter<boolean>()
  private darkMode = true
  private iconDrawer = false
  public colors = {
    rocket_pink: {
      50: '#ffe0f0',
      100: '#ffb3d9',
      200: '#ff80c0',
      300: '#ff4da7',
      400: '#ff2694',
      500: 'hsl(330, 100%, 50%)',
      600: '#ff0079',
      700: '#ff006e',
      800: '#ff0064',
      900: '#ff0051',
      1800: 'hsl(330, 53%, 30%)',
      A100: '#ffffff',
      A200: '#fff2f5',
      A400: '#ffbfcf',
      A700: '#ffa6bc',
    },
    rocket_purple: {
      50: '#efeaf9',
      100: '#d7cbef',
      200: '#bda9e5',
      300: '#a287da',
      400: '#8e6dd2',
      500: 'hsl(260, 53%, 56%)',
      600: '#724cc5',
      700: '#6742bd',
      800: '#5d39b7',
      900: '#4a29ab',
      1800: 'hsl(260, 53%, 30%)',
      A100: '#f0ebff',
      A200: '#c9b8ff',
      A400: '#a285ff',
      A700: '#8e6cff',
    },
    rocket_turquoise: {
      50: '#e7fcf7',
      100: '#c2f8ea',
      200: '#9af4dc',
      300: '#71efce',
      400: '#52ebc4',
      500: 'hsl(164, 80%, 56%)',
      600: '#2fe5b2',
      700: '#27e2aa',
      800: '#21dea2',
      900: '#15d893',
      1800: 'hsl(164, 53%, 30%)',
      A100: '#ffffff',
      A200: '#d5ffef',
      A400: '#a2ffdc',
      A700: '#89ffd2',
    },
    rocket_blue: {
      50: '#edf2ff',
      100: '#d1deff',
      200: '#b2c9ff',
      300: '#93b3ff',
      400: '#7ca2ff',
      500: 'hsl(222, 100%, 70%)',
      600: '#5d8aff',
      700: '#537fff',
      800: '#4975ff',
      900: '#3763ff',
      1800: 'hsl(222, 53%, 30%)',
      A100: '#ffffff',
      A200: '#ffffff',
      A400: '#e1e7ff',
      A700: '#c8d3ff',
    },
    rocket_red: {
      50: '#fde6e6',
      100: '#fabfbf',
      200: '#f79595',
      300: '#f46b6b',
      400: '#f14b4b',
      500: 'hsl(0, 86%, 55%)',
      600: '#ed2626',
      700: '#eb2020',
      800: '#e81a1a',
      900: '#e41010',
      1800: 'hsl(0, 53%, 30%)',
      A100: '#ffffff',
      A200: '#ffdede',
      A400: '#ffabab',
      A700: '#ff9191',
    },
    divider: 'rgb(59, 59, 59)',
    light_grey: 'rgb(217, 217, 217)',
    grey: 'rgb(150, 150, 150)',
    date_dark: 'rgb(51, 51, 51)',
    date_font: 'rgb(100,100,100)',
    date_font_dark: 'rgb(255,255,255)',
  }

  constructor() {
    if (localStorage.getItem('drawer')) this.iconDrawer = localStorage.getItem('drawer') == 'icon'
  }

  public toggleMode() {
    this.darkMode = !this.darkMode
    this.modeUpdated.emit(this.darkMode)
    localStorage.setItem('theme', this.darkMode ? 'dark' : 'light')
  }

  public toggleDrawer() {
    this.iconDrawer = !this.iconDrawer
    localStorage.setItem('drawer', this.iconDrawer ? 'icon' : 'full')
  }

  public getMode() {
    return this.darkMode
  }

  public getDrawer() {
    return this.iconDrawer
  }
}
