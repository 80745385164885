import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import * as moment from 'moment'
import { Observable } from 'rxjs'

@Injectable()
export class AuthChildGuard implements CanActivateChild {
  constructor(private router: Router) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const now = moment().format('YYYY-MM-DD HH:mm:ss')

    if (now <= localStorage.getItem('expiring_date')) {
      return true
    }

    localStorage.removeItem('expiring_date')
    localStorage.removeItem('me')
    this.router.navigate(['/login'])
    return false
  }
}
