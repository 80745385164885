import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { FORBIDDEN, UNAUTHORIZED } from 'http-status'
import { Router } from '@angular/router'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const logged = localStorage.getItem('expiring_date')
    if (logged && !req.headers.has('Skip-Interceptor-Auth')) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
      const requestClone = req.clone({
        headers,
        withCredentials: true,
      })
      return next.handle(requestClone).pipe(catchError((error) => this.handleAuthError(error)))
    } else {
      return next.handle(req)
    }
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    if ([FORBIDDEN, UNAUTHORIZED].includes(error.status)) {
      this.router.navigateByUrl(`/login`)
      localStorage.removeItem('me')
      localStorage.removeItem('expiring_date')
      return of(error.message)
    }
    return throwError(error)
  }
}
