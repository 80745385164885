import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() heading: string
  @Input() icon: string
  @Input() original: string
  @Input() originalIcon: string
  @Input() originalRoute: string
  @Input() useDefault = true

  constructor() {}

  ngOnInit() {}
}
