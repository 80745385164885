import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'

@Injectable()
export class CacheManagerInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.router.url.includes('ignore_cache=true') && request.method === 'GET') {
      const cloneReq = request.clone({
        params: request.params.append('ignore_cache', 'true'),
      })
      return next.handle(cloneReq)
    }
    return next.handle(request)
  }
}
