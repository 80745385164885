import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { StatComponent } from './stat.component'
import { MatCardModule } from '@angular/material/card'

@NgModule({
  declarations: [StatComponent],
  imports: [CommonModule, MatCardModule],
  exports: [StatComponent],
})
export class StatModule {}
